import React from "react";
import HtmlReactParser from "html-react-parser";
import {
  FloorplanTabpanel,
  GalleryItem,
  GalleryTabpanel,
  HeroContainer,
  HeroInfoItem,
  HeroTablist,
  HeroTabpanel,
  HeroTabpanels,
  InfoTabpanel,
  SubtitleText,
  TourTabpanel,
  VideoBlockHeading,
} from "raffle-house-uikit";
import { heroGalleryImages, heroInfoData } from "../../lib/static-data";
import "./styles.css";

const withUrl = (imageName) =>
  `${process.env.REACT_APP_AWS_S3_URL}gallery/home/Wiltshire/${imageName}.jpg`;

export default function Hero() {
  return (
    <HeroContainer>
      <HeroTablist />
      <HeroTabpanels>
        <HeroTabpanel id="tour">
          <TourTabpanel
            video={
              process.env.REACT_APP_AWS_S3_URL +
              "videos/01.01.2025/competition-01.01.2025.mp4"
            }
            tabletVideo={
              process.env.REACT_APP_AWS_S3_URL +
              "videos/01.01.2025/competition-01.01.2025.mp4"
            }
            mobileVideo={
              process.env.REACT_APP_AWS_S3_URL +
              "videos/01.01.2025/competition-mobile-01.01.2025.mp4"
            }
            poster={
              process.env.REACT_APP_AWS_S3_URL +
              "videos/01.01.2025/preview-competition-01.01.2025.jpg"
            }
            tabletPoster={
              process.env.REACT_APP_AWS_S3_URL +
              "videos/01.01.2025/preview-competition-01.01.2025.jpg"
            }
            mobilePoster={
              process.env.REACT_APP_AWS_S3_URL +
              "videos/preview-mobile-competition-video-01-11-2024.jpg"
            }
          >
            <div className="video-block-badge">
            <svg
                width="875"
                height="875"
                viewBox="0 0 875 875"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M875.003 437.502C875.003 679.127 679.127 875.003 437.502 875.003C195.876 875.003 0 679.127 0 437.502C0 195.876 195.876 0 437.502 0C679.127 0 875.003 195.876 875.003 437.502Z"
                  fill="#043B3B"
                />
                <path
                  d="M774.994 436.163C774.994 623.251 623.319 774.916 436.219 774.916C249.119 774.916 97.4451 623.251 97.4451 436.163C97.4451 249.075 249.119 97.4102 436.219 97.4102C623.319 97.4102 774.994 249.075 774.994 436.163Z"
                  stroke="white"
                />
                <path
                  d="M190.656 377.451V353.079L230.165 324.152C243.425 314.483 255.151 305.772 255.151 294.745C255.151 289.391 252.355 285.156 246.04 285.156C239.456 285.156 235.577 289.231 235.577 298.341C235.577 299.619 235.577 300.978 235.757 302.496H186.867C188.13 273.409 212.214 259.984 247.303 259.984C278.153 259.984 302.778 271.571 302.778 296.263C302.778 316.88 284.918 328.786 263.45 340.693L246.852 349.882H303.319V377.451H190.656Z"
                  fill="white"
                />
                <path
                  d="M315.854 340.213V309.848H373.854V340.213H315.854Z"
                  fill="white"
                />
                <path
                  d="M450.252 377.451V356.515H388.012V327.348L428.062 262.861H493.73V329.745H509.966V356.515H493.73V377.451H450.252ZM427.341 329.745H450.252V291.389L427.341 329.745Z"
                  fill="white"
                />
                <path
                  d="M524.575 340.213V309.848H582.576V340.213H524.575Z"
                  fill="white"
                />
                <path
                  d="M625.869 377.451V306.572H592.494V276.206H605.844C623.163 276.206 626.14 272.93 626.411 262.861H672.053V377.451H625.869Z"
                  fill="white"
                />
                <path
                  d="M186.484 492.753V414.624H226.275C256.473 414.624 276.092 425.139 276.092 453.253C276.092 481.475 256.842 492.753 225.045 492.753H186.484ZM219.08 473.684H222.647C234.517 473.684 241.036 468.508 241.036 453.253C241.036 438.488 235.439 433.693 222.647 433.693H219.08V473.684Z"
                  fill="white"
                />
                <path
                  d="M286.422 492.753V414.624H332.118C357.887 414.624 369.757 421.434 369.757 435.709C369.757 448.131 361.147 453.906 351.06 456.304C363.73 458.429 370.003 462.024 370.003 473.575V492.753H337.284V472.758C337.284 466.928 333.102 464.258 325.291 464.258H319.018V492.753H286.422ZM319.018 448.131H325.598C332.733 448.131 337.653 445.952 337.653 439.468C337.653 433.638 333.04 431.514 325.598 431.514H319.018V448.131Z"
                  fill="white"
                />
                <path
                  d="M381.44 492.753V414.624H454.135V433.039H414.036V443.772H446.816V462.297H414.036V474.229H454.135V492.753H381.44Z"
                  fill="white"
                />
                <path
                  d="M458.745 492.753L485.498 414.624H531.44L557.948 492.753H523.875L520.185 479.568H496.2L492.448 492.753H458.745ZM501.12 459.954H515.45L508.377 433.366L501.12 459.954Z"
                  fill="white"
                />
                <path
                  d="M565.325 492.753V414.624H609.053L622.276 460.553L635.622 414.624H678.058V492.753H648.045V445.407L634.392 492.753H609.176L595.338 446.17V492.753H565.325Z"
                  fill="white"
                />
                <path
                  d="M186 608.055V529.926H225.792C255.989 529.926 275.608 540.441 275.608 568.555C275.608 596.777 256.358 608.055 224.562 608.055H186ZM218.596 588.986H222.163C234.033 588.986 240.552 583.81 240.552 568.555C240.552 553.79 234.955 548.995 222.163 548.995H218.596V588.986Z"
                  fill="white"
                />
                <path
                  d="M285.938 608.055V529.926H331.634C357.403 529.926 369.273 536.736 369.273 551.011C369.273 563.433 360.663 569.208 350.576 571.606C363.246 573.731 369.519 577.327 369.519 588.877V608.055H336.8V588.06C336.8 582.23 332.618 579.56 324.807 579.56H318.534V608.055H285.938ZM318.534 563.433H325.115C332.249 563.433 337.169 561.254 337.169 554.77C337.169 548.941 332.556 546.816 325.115 546.816H318.534V563.433Z"
                  fill="white"
                />
                <path
                  d="M374.191 608.055L400.944 529.926H446.886L473.393 608.055H439.321L435.631 594.87H411.645L407.894 608.055H374.191ZM416.565 575.256H430.895L423.823 548.668L416.565 575.256Z"
                  fill="white"
                />
                <path
                  d="M488.397 608.055L470.377 529.926H502.85L510.415 579.887L520.255 529.926H551.498L561.646 579.778L569.149 529.926H600.392L582.433 608.055H544.364L535.446 559.456L526.774 608.055H488.397Z"
                  fill="white"
                />
                <path
                  d="M644.794 609.962C620.439 609.962 603.096 602.825 603.096 585.499H636.368C636.368 591.056 639.689 593.617 644.794 593.617C648.914 593.617 651.866 591.983 651.866 588.332C651.866 584.409 648.607 582.666 642.272 580.704L630.095 576.945C615.273 572.368 603.957 566.975 603.957 553.245C603.957 535.32 622.407 528.182 644.794 528.182C664.105 528.182 684.155 534.72 684.155 551.937H651.866C651.743 546.816 648.73 544.418 644.117 544.418C639.874 544.418 636.983 546.38 636.983 549.485C636.983 553.245 640.796 554.498 646.393 556.296L657.771 559.946C673.7 565.068 686 570.679 686 585.499C686 603.261 667.918 609.962 644.794 609.962Z"
                  fill="white"
                />
                <path
                  d="M453.351 80.9518L455.22 40.3066L438.811 39.5518L439.021 35L477.157 36.7545L476.948 41.3062L460.54 40.5514L458.671 81.1966L453.351 80.9518Z"
                  fill="white"
                />
                <path
                  d="M482.542 83.3807L476.14 50.7197L481.522 51.6044L486.523 80.1328L485.003 79.8829L499.186 54.5085L503.555 55.2267L508.682 83.7758L507.226 83.5364L521.22 58.1307L526.348 58.9738L509.767 87.8565L504.892 87.055L499.373 57.726L502.095 58.1736L487.481 84.1926L482.542 83.3807Z"
                  fill="white"
                />
                <path
                  d="M536.234 95.5076C533.318 94.66 530.977 93.2891 529.211 91.3947C527.445 89.5003 526.3 87.2296 525.777 84.5827C525.308 81.9066 525.532 78.9868 526.451 75.8232C527.37 72.6596 528.739 70.0946 530.558 68.128C532.429 66.1324 534.618 64.8081 537.124 64.1552C539.63 63.5022 542.341 63.5996 545.258 64.4471C548.174 65.2946 550.515 66.6656 552.281 68.56C554.047 70.4544 555.165 72.7396 555.635 75.4157C556.158 78.0627 555.96 80.9679 555.041 84.1315C554.122 87.2951 552.726 89.8747 550.855 91.8704C549.036 93.8369 546.874 95.1466 544.368 95.7995C541.862 96.4524 539.151 96.3551 536.234 95.5076ZM537.398 91.5018C540.232 92.3254 542.757 91.99 544.973 90.4954C547.243 88.9716 548.92 86.3404 550.006 82.6016C551.081 78.9039 551.062 75.8243 549.951 73.3628C548.881 70.9132 546.928 69.2766 544.094 68.4529C541.301 67.6412 538.776 67.9767 536.519 69.4593C534.261 70.942 532.596 73.5322 531.522 77.2298C530.435 80.9686 530.442 84.0893 531.541 86.5919C532.653 89.0534 534.605 90.69 537.398 91.5018Z"
                  fill="white"
                />
                <path
                  d="M571.596 106.998L590.817 66.0404L604.641 72.5299C609.094 74.6204 612.478 77.1776 614.791 80.2016C617.162 83.205 618.424 86.6092 618.575 90.4142C618.784 94.1987 617.852 98.2985 615.78 102.714C613.708 107.129 611.141 110.485 608.079 112.781C605.055 115.095 601.622 116.319 597.779 116.453C593.993 116.566 589.873 115.578 585.42 113.487L571.596 106.998ZM578.267 105.167L586.98 109.257C592.363 111.784 597.016 112.29 600.939 110.776C604.902 109.281 608.164 105.802 610.727 100.342C613.29 94.8806 613.871 90.1674 612.472 86.202C611.09 82.1979 607.728 78.9415 602.384 76.433L593.671 72.343L578.267 105.167Z"
                  fill="white"
                />
                <path
                  d="M611.037 126.665L626.88 99.8001L631.246 102.376L627.497 108.733L626.945 108.407C628.853 106.602 630.907 105.603 633.107 105.41C635.328 105.181 637.764 105.724 640.415 107.039L642.116 107.819L640.165 111.884L637.151 110.553C634.515 109.296 632.103 108.966 629.914 109.562C627.783 110.143 626.012 111.631 624.599 114.026L615.569 129.338L611.037 126.665Z"
                  fill="white"
                />
                <path
                  d="M640.619 146.246C637.916 144.363 635.949 142.21 634.717 139.788C633.544 137.355 633.099 134.776 633.38 132.052C633.696 129.352 634.807 126.633 636.715 123.895C638.573 121.227 640.713 119.277 643.135 118.044C645.582 116.777 648.109 116.217 650.717 116.365C653.359 116.538 655.892 117.468 658.314 119.155C660.63 120.769 662.259 122.66 663.2 124.827C664.14 126.995 664.401 129.314 663.983 131.786C663.6 134.283 662.504 136.83 660.694 139.428L659.557 141.06L639.392 127.012L641.336 124.221L658.658 136.289L657.339 136.386C659.295 133.578 660.179 130.961 659.991 128.536C659.863 126.1 658.605 124.051 656.218 122.388C654.428 121.141 652.608 120.499 650.758 120.461C648.967 120.412 647.242 120.905 645.583 121.94C643.949 122.939 642.459 124.404 641.115 126.335L640.895 126.651C639.403 128.792 638.478 130.833 638.118 132.772C637.793 134.735 638.046 136.58 638.877 138.306C639.732 139.997 641.108 141.503 643.003 142.823C644.512 143.875 646.128 144.661 647.85 145.183C649.631 145.694 651.533 145.846 653.554 145.638L652.938 149.666C651.046 149.913 648.985 149.728 646.755 149.113C644.525 148.498 642.48 147.542 640.619 146.246Z"
                  fill="white"
                />
                <path
                  d="M662.83 163.398C661.193 162.021 659.981 160.471 659.195 158.747C658.468 157.019 658.197 155.281 658.38 153.534C658.563 151.788 659.232 150.227 660.388 148.851C661.875 147.083 663.487 146.063 665.224 145.791C666.995 145.547 669.09 146.079 671.511 147.388C673.964 148.725 676.991 150.907 680.593 153.936L682.803 155.794L680.614 158.398L678.453 156.581C676.456 154.901 674.731 153.562 673.279 152.564C671.826 151.567 670.569 150.901 669.508 150.568C668.447 150.234 667.533 150.192 666.765 150.441C665.997 150.689 665.283 151.206 664.622 151.992C663.493 153.335 663.029 154.817 663.23 156.44C663.464 158.09 664.383 159.589 665.988 160.938C667.297 162.04 668.705 162.692 670.21 162.896C671.748 163.127 673.249 162.937 674.715 162.324C676.212 161.739 677.553 160.743 678.737 159.335L683.444 153.735C685.151 151.705 685.967 149.903 685.892 148.331C685.844 146.725 684.887 145.138 683.021 143.569C681.58 142.357 680.01 141.4 678.31 140.697C676.637 139.961 674.711 139.459 672.533 139.192L673.594 135.138C674.941 135.264 676.333 135.569 677.771 136.052C679.237 136.502 680.656 137.108 682.027 137.87C683.399 138.632 684.625 139.467 685.705 140.376C687.768 142.111 689.181 143.886 689.946 145.703C690.737 147.486 690.882 149.341 690.381 151.267C689.907 153.161 688.776 155.172 686.986 157.3L674.35 172.331L670.618 169.192L675.284 163.641L675.726 164.013C674.418 165.037 673.016 165.675 671.519 165.926C670.056 166.204 668.577 166.135 667.084 165.717C665.59 165.3 664.173 164.527 662.83 163.398Z"
                  fill="white"
                />
                <path
                  d="M680.573 177.676L703.311 156.332L706.78 160.028L701.727 164.771L701.244 164.257C702.749 163.548 704.244 163.171 705.728 163.128C707.273 163.086 708.717 163.374 710.059 163.991C711.462 164.611 712.734 165.529 713.876 166.745C715.691 168.68 716.687 170.678 716.863 172.742C717.1 174.808 716.442 176.951 714.889 179.172L714.231 178.47C716.504 177.392 718.813 177.102 721.158 177.6C723.563 178.1 725.673 179.317 727.488 181.251C729.099 182.967 730.088 184.678 730.458 186.385C730.857 188.123 730.649 189.902 729.835 191.723C729.052 193.514 727.647 195.361 725.619 197.264L711.443 210.572L707.887 206.781L721.875 193.65C723.965 191.688 725.157 189.894 725.45 188.269C725.775 186.615 725.132 184.93 723.522 183.214C722.381 181.997 721.074 181.199 719.604 180.82C718.162 180.471 716.661 180.56 715.101 181.086C713.601 181.613 712.119 182.565 710.653 183.941L697.787 196.019L694.23 192.229L708.219 179.097C710.308 177.135 711.485 175.326 711.75 173.67C712.075 172.015 711.446 170.346 709.866 168.661C708.695 167.414 707.388 166.616 705.947 166.267C704.505 165.919 703.004 166.007 701.444 166.533C699.945 167.061 698.462 168.013 696.996 169.389L684.13 181.466L680.573 177.676Z"
                  fill="white"
                />
                <path
                  d="M725.057 229.16L763.093 204.67L765.941 209.095L749.108 219.933L763.8 242.758L780.633 231.92L783.481 236.345L745.445 260.835L742.597 256.41L759.97 245.225L745.278 222.399L727.905 233.585L725.057 229.16Z"
                  fill="white"
                />
                <path
                  d="M754 279.63C752.654 276.907 752.082 274.254 752.285 271.672C752.488 269.09 753.389 266.712 754.987 264.538C756.642 262.383 758.945 260.576 761.898 259.117C764.851 257.657 767.666 256.933 770.344 256.945C773.08 256.977 775.535 257.696 777.709 259.103C779.884 260.509 781.644 262.574 782.99 265.298C784.336 268.021 784.907 270.673 784.704 273.255C784.501 275.838 783.572 278.206 781.917 280.36C780.319 282.534 778.044 284.351 775.091 285.811C772.139 287.271 769.295 287.985 766.559 287.953C763.881 287.941 761.455 287.232 759.28 285.825C757.106 284.418 755.345 282.353 754 279.63ZM757.738 277.782C759.046 280.428 761 282.063 763.599 282.688C766.255 283.331 769.328 282.79 772.817 281.065C776.269 279.359 778.526 277.264 779.589 274.782C780.672 272.338 780.559 269.792 779.251 267.146C777.962 264.538 776.009 262.903 773.391 262.24C770.773 261.578 767.738 262.099 764.287 263.806C760.798 265.531 758.502 267.644 757.4 270.146C756.337 272.628 756.449 275.174 757.738 277.782Z"
                  fill="white"
                />
                <path
                  d="M763.778 298.431L793.202 288.102L794.881 292.886L788.342 295.181L788.109 294.515C789.772 294.475 791.291 294.735 792.667 295.295C794.096 295.882 795.3 296.729 796.278 297.836C797.311 298.97 798.103 300.324 798.656 301.899C799.534 304.402 799.637 306.633 798.965 308.592C798.347 310.577 796.879 312.271 794.56 313.675L794.242 312.766C796.757 312.699 798.986 313.368 800.929 314.772C802.927 316.201 804.365 318.168 805.244 320.671C806.023 322.891 806.237 324.856 805.885 326.567C805.547 328.318 804.638 329.861 803.158 331.197C801.717 332.519 799.686 333.64 797.062 334.561L778.717 341.001L776.996 336.096L795.098 329.741C797.803 328.792 799.618 327.633 800.543 326.266C801.509 324.884 801.602 323.083 800.823 320.862C800.27 319.288 799.398 318.03 798.206 317.088C797.028 316.186 795.62 315.66 793.981 315.51C792.396 315.387 790.655 315.658 788.758 316.324L772.109 322.168L770.387 317.263L788.49 310.909C791.194 309.959 793.002 308.781 793.913 307.373C794.879 305.991 794.979 304.21 794.214 302.03C793.647 300.415 792.775 299.157 791.597 298.255C790.42 297.354 789.011 296.828 787.372 296.678C785.787 296.554 784.046 296.825 782.149 297.491L765.5 303.336L763.778 298.431Z"
                  fill="white"
                />
                <path
                  d="M782.196 361.464C781.486 358.248 781.499 355.331 782.235 352.715C783.022 350.131 784.443 347.933 786.496 346.121C788.558 344.351 791.218 343.106 794.476 342.386C797.651 341.685 800.546 341.703 803.162 342.439C805.819 343.167 808.056 344.469 809.873 346.346C811.698 348.265 812.929 350.666 813.566 353.549C814.175 356.306 814.09 358.8 813.313 361.031C812.535 363.262 811.155 365.145 809.172 366.678C807.198 368.254 804.665 369.383 801.574 370.065L799.632 370.494L794.332 346.493L797.652 345.76L802.205 366.377L801.17 365.554C804.511 364.816 806.935 363.492 808.44 361.582C809.997 359.704 810.462 357.345 809.834 354.504C809.364 352.374 808.461 350.667 807.127 349.385C805.843 348.135 804.241 347.327 802.321 346.963C800.442 346.589 798.354 346.656 796.057 347.164L795.681 347.247C793.133 347.81 791.069 348.682 789.49 349.863C787.921 351.086 786.856 352.614 786.297 354.446C785.78 356.269 785.77 358.309 786.269 360.565C786.665 362.361 787.32 364.035 788.232 365.586C789.195 367.17 790.49 368.571 792.117 369.789L788.933 372.332C787.375 371.23 785.985 369.697 784.763 367.733C783.541 365.768 782.685 363.679 782.196 361.464Z"
                  fill="white"
                />
                <path
                  d="M786.945 389.548C786.733 387.936 786.696 386.345 786.834 384.773C786.935 383.249 787.175 381.793 787.554 380.406C787.98 379.055 788.534 377.86 789.217 376.821L793.061 377.998C792.389 379.122 791.856 380.314 791.461 381.574C791.108 382.829 790.886 384.088 790.793 385.352C790.701 386.616 790.738 387.884 790.906 389.156C791.224 391.574 791.909 393.34 792.962 394.452C794.014 395.565 795.304 396.021 796.831 395.82C798.018 395.664 798.898 395.138 799.471 394.243C800.085 393.342 800.469 391.997 800.622 390.207L801.132 384.25C801.332 381.505 802.024 379.386 803.208 377.892C804.397 376.441 806.074 375.573 808.237 375.288C810.145 375.037 811.842 375.309 813.328 376.106C814.861 376.94 816.13 378.219 817.133 379.942C818.136 381.666 818.797 383.737 819.115 386.154C819.305 387.597 819.338 388.995 819.214 390.349C819.091 391.703 818.807 392.992 818.364 394.215C817.969 395.476 817.403 396.586 816.667 397.546L812.815 396.305C813.472 395.399 813.988 394.403 814.363 393.318C814.78 392.227 815.044 391.114 815.153 389.977C815.31 388.878 815.316 387.777 815.171 386.674C814.859 384.298 814.155 382.557 813.06 381.45C811.965 380.342 810.654 379.889 809.128 380.09C807.94 380.246 807.015 380.757 806.352 381.621C805.689 382.485 805.294 383.745 805.167 385.402L804.72 391.351C804.489 394.186 803.806 396.369 802.67 397.9C801.582 399.467 799.935 400.396 797.73 400.686C795.864 400.932 794.164 400.638 792.631 399.804C791.139 398.965 789.889 397.662 788.88 395.896C787.919 394.167 787.274 392.051 786.945 389.548Z"
                  fill="white"
                />
                <path
                  d="M790.298 425.416L817.434 424.912L817.32 418.752L821.362 418.677L821.502 426.249L820.129 424.862L822.567 424.817C826.715 424.74 829.878 425.708 832.054 427.721C834.231 429.778 835.488 432.964 835.825 437.28L836 439.78L832.031 440.303L831.784 437.355C831.623 435.603 831.211 434.177 830.549 433.077C829.887 431.977 828.952 431.181 827.745 430.69C826.538 430.199 825.058 429.97 823.305 430.002L820.61 430.052L821.558 429.264L821.739 439.018L817.697 439.093L817.531 430.109L790.394 430.613L790.298 425.416Z"
                  fill="white"
                />
                <path
                  d="M789.186 456.648C789.357 453.616 790.167 451.026 791.617 448.879C793.066 446.733 795.021 445.108 797.483 444.004C799.985 442.945 802.88 442.509 806.168 442.694C809.457 442.88 812.263 443.638 814.587 444.97C816.952 446.346 818.733 448.182 819.932 450.478C821.13 452.774 821.644 455.438 821.473 458.471C821.302 461.504 820.492 464.094 819.042 466.24C817.593 468.387 815.617 469.989 813.116 471.048C810.654 472.152 807.779 472.611 804.491 472.425C801.202 472.24 798.376 471.459 796.011 470.082C793.687 468.751 791.926 466.938 790.727 464.642C789.529 462.346 789.015 459.681 789.186 456.648ZM793.35 456.884C793.184 459.831 794.077 462.217 796.031 464.041C798.025 465.911 800.965 466.955 804.851 467.175C808.695 467.392 811.691 466.682 813.84 465.047C815.986 463.454 817.143 461.184 817.309 458.236C817.473 455.331 816.579 452.945 814.628 451.078C812.677 449.211 809.78 448.169 805.936 447.952C802.05 447.733 799.011 448.44 796.819 450.073C794.67 451.709 793.514 453.979 793.35 456.884Z"
                  fill="white"
                />
                <path
                  d="M787.962 477.298L818.864 481.478L818.185 486.502L810.872 485.513L810.959 484.877C813.362 485.936 815.079 487.442 816.11 489.395C817.183 491.353 817.628 493.81 817.445 496.764L817.386 498.634L812.884 498.414L812.941 495.118C813.076 492.201 812.444 489.849 811.046 488.062C809.684 486.324 807.625 485.268 804.87 484.896L787.257 482.513L787.962 477.298Z"
                  fill="white"
                />
                <path
                  d="M778.113 525.958C778.985 522.383 780.594 519.935 782.942 518.614C785.289 517.293 788.229 517.063 791.761 517.924L807.657 521.8L809.101 515.877L813.029 516.835L811.585 522.758L820.873 525.023L819.642 530.073L810.354 527.808L808.013 537.41L804.086 536.453L806.426 526.851L791.029 523.096C788.66 522.518 786.731 522.576 785.241 523.27C783.792 523.974 782.795 525.448 782.247 527.692C782.085 528.358 782.006 529.043 782.01 529.748C782.015 530.454 782.034 531.097 782.068 531.678L777.991 531.609C777.831 530.997 777.754 530.142 777.758 529.042C777.762 527.942 777.88 526.914 778.113 525.958Z"
                  fill="white"
                />
                <path
                  d="M776.376 534.826L819.335 548.999L817.707 553.936L797.781 547.362L798.043 546.57C800.117 548.2 801.429 550.165 801.977 552.463C802.552 554.815 802.425 557.251 801.594 559.77C800.817 562.127 799.747 563.936 798.385 565.199C797.009 566.502 795.32 567.252 793.319 567.447C791.358 567.656 789.058 567.325 786.417 566.454L767.954 560.363L769.582 555.426L787.741 561.417C789.569 562.02 791.152 562.272 792.491 562.173C793.816 562.115 794.931 561.672 795.835 560.844C796.725 560.056 797.432 558.87 797.954 557.285C798.544 555.498 798.674 553.806 798.344 552.211C798.015 550.615 797.28 549.224 796.14 548.037C795 546.85 793.496 545.948 791.627 545.332L774.748 539.763L776.376 534.826Z"
                  fill="white"
                />
                <path
                  d="M758.938 579.887C760.261 576.87 761.99 574.522 764.127 572.843C766.286 571.22 768.729 570.282 771.455 570.029C774.165 569.816 777.047 570.379 780.103 571.718C783.08 573.024 785.408 574.745 787.087 576.883C788.805 579.037 789.844 581.408 790.205 583.995C790.548 586.622 790.128 589.287 788.943 591.991C787.809 594.577 786.271 596.542 784.327 597.885C782.384 599.229 780.159 599.935 777.654 600.005C775.131 600.113 772.42 599.532 769.521 598.261L767.699 597.462L777.565 574.95L780.68 576.316L772.205 595.654L771.853 594.378C774.987 595.752 777.725 596.112 780.067 595.457C782.431 594.858 784.197 593.227 785.365 590.562C786.241 588.564 786.518 586.653 786.196 584.83C785.896 583.064 785.078 581.467 783.741 580.041C782.444 578.631 780.718 577.454 778.564 576.509L778.211 576.355C775.821 575.307 773.641 574.794 771.669 574.818C769.68 574.88 767.919 575.486 766.388 576.636C764.895 577.804 763.685 579.446 762.758 581.562C762.019 583.247 761.561 584.985 761.384 586.775C761.228 588.622 761.448 590.517 762.045 592.46L757.974 592.638C757.365 590.829 757.146 588.771 757.317 586.464C757.487 584.156 758.028 581.964 758.938 579.887Z"
                  fill="white"
                />
                <path
                  d="M734.421 604.421L770.779 626.881L768.115 631.194L761.837 627.316L762.174 626.77C763.889 628.634 764.819 630.792 764.963 633.245C765.122 635.757 764.459 638.214 762.975 640.616C761.514 642.982 759.68 644.69 757.473 645.741C755.28 646.85 752.854 647.237 750.195 646.902C747.55 646.626 744.808 645.611 741.969 643.857C739.167 642.126 737.032 640.128 735.564 637.864C734.111 635.659 733.359 633.334 733.31 630.89C733.261 628.446 733.978 626.022 735.461 623.62C736.945 621.217 738.816 619.532 741.073 618.563C743.367 617.616 745.724 617.463 748.144 618.103L747.74 618.759L731.69 608.844L734.421 604.421ZM739.583 624.884C738.032 627.395 737.68 629.919 738.528 632.454C739.39 635.048 741.458 637.357 744.734 639.38C748.046 641.426 751.036 642.242 753.704 641.829C756.351 641.452 758.449 640.008 760 637.496C761.529 635.021 761.88 632.497 761.055 629.926C760.23 627.354 758.161 625.045 754.849 623C751.574 620.976 748.584 620.16 745.879 620.551C743.21 620.964 741.112 622.408 739.583 624.884Z"
                  fill="white"
                />
                <path
                  d="M725.163 640.085L750.292 658.553L747.291 662.639L741.344 658.269L741.724 657.752C743.328 659.832 744.114 661.977 744.085 664.185C744.089 666.418 743.304 668.788 741.729 671.293L740.782 672.907L736.935 670.557L738.562 667.692C740.078 665.195 740.649 662.828 740.276 660.59C739.913 658.411 738.611 656.499 736.37 654.852L722.047 644.326L725.163 640.085Z"
                  fill="white"
                />
                <path
                  d="M742.653 681.444L747.179 685.134L743.124 690.108L738.598 686.417L742.653 681.444ZM712.063 657.324L736.231 677.034L732.946 681.062L708.779 661.352L712.063 657.324Z"
                  fill="white"
                />
                <path
                  d="M693.596 677.677C695.708 675.376 698.034 673.853 700.575 673.108C703.117 672.362 705.705 672.357 708.34 673.092C710.978 673.887 713.495 675.383 715.89 677.582C718.317 679.809 720.008 682.174 720.964 684.676C721.89 687.21 722.06 689.746 721.472 692.285C720.885 694.823 719.536 697.243 717.425 699.545C716.123 700.963 714.584 702.135 712.807 703.059C711.031 703.983 709.266 704.483 707.513 704.558L705.938 700.674C707.659 700.57 709.231 700.154 710.653 699.427C712.046 698.731 713.191 697.895 714.087 696.917C716.285 694.522 717.221 691.983 716.894 689.302C716.598 686.65 715.048 684.037 712.242 681.463C709.5 678.946 706.733 677.597 703.939 677.414C701.178 677.26 698.698 678.381 696.5 680.777C695.603 681.754 694.868 682.967 694.295 684.415C693.692 685.894 693.429 687.51 693.504 689.263L689.5 688.028C689.425 686.275 689.77 684.443 690.535 682.533C691.304 680.683 692.324 679.064 693.596 677.677Z"
                  fill="white"
                />
                <path
                  d="M673.358 697.487C675.78 695.254 678.307 693.798 680.938 693.119C683.567 692.499 686.181 692.622 688.779 693.485C691.346 694.377 693.76 696.05 696.021 698.504C698.224 700.895 699.665 703.407 700.345 706.038C701.053 708.702 701.053 711.29 700.345 713.805C699.606 716.348 698.15 718.62 695.98 720.62C693.904 722.534 691.706 723.716 689.387 724.166C687.068 724.616 684.747 724.37 682.424 723.427C680.07 722.513 677.82 720.892 675.675 718.564L674.327 717.101L692.4 700.445L694.704 702.947L679.18 717.254L679.369 715.945C681.688 718.462 684.052 719.89 686.46 720.231C688.865 720.632 691.137 719.847 693.277 717.876C694.881 716.397 695.901 714.758 696.338 712.96C696.773 711.221 696.664 709.431 696.013 707.587C695.391 705.775 694.283 704.004 692.688 702.274L692.427 701.991C690.659 700.071 688.867 698.726 687.052 697.956C685.206 697.214 683.35 697.063 681.486 697.501C679.65 697.971 677.883 698.989 676.184 700.554C674.831 701.801 673.714 703.209 672.833 704.777C671.949 706.406 671.39 708.23 671.156 710.249L667.356 708.777C667.525 706.876 668.15 704.904 669.233 702.859C670.316 700.814 671.691 699.023 673.358 697.487Z"
                  fill="white"
                />
                <path
                  d="M636.693 726.511C639.185 724.776 641.723 723.816 644.306 723.633C646.889 723.45 649.374 723.987 651.761 725.245C654.137 726.561 656.267 728.571 658.149 731.275C660.031 733.978 661.165 736.655 661.551 739.306C661.927 742.016 661.581 744.551 660.513 746.911C659.446 749.271 657.666 751.319 655.173 753.054C652.681 754.79 650.143 755.75 647.56 755.933C644.977 756.116 642.497 755.549 640.121 754.232C637.734 752.975 635.599 750.995 633.717 748.291C631.835 745.588 630.706 742.881 630.33 740.171C629.944 737.52 630.285 735.015 631.353 732.655C632.42 730.295 634.2 728.247 636.693 726.511ZM639.076 729.935C636.654 731.622 635.327 733.797 635.097 736.46C634.855 739.183 635.847 742.142 638.071 745.337C640.271 748.497 642.678 750.418 645.29 751.101C647.868 751.808 650.368 751.318 652.79 749.631C655.177 747.969 656.503 745.794 656.769 743.106C657.035 740.418 656.068 737.495 653.868 734.335C651.643 731.14 649.212 729.183 646.575 728.465C643.963 727.782 641.463 728.272 639.076 729.935Z"
                  fill="white"
                />
                <path
                  d="M618.609 738.887L632.395 762.272L637.701 759.142L639.754 762.625L633.232 766.471L633.764 764.593L635.002 766.694C637.11 770.269 637.822 773.499 637.14 776.385C636.421 779.293 634.266 781.954 630.675 784.37L628.584 785.752L626.177 782.553L628.621 780.887C630.067 779.886 631.106 778.826 631.738 777.708C632.37 776.591 632.603 775.385 632.437 774.093C632.27 772.8 631.742 771.398 630.851 769.887L629.482 767.565L630.634 768.003L622.233 772.958L620.18 769.475L627.918 764.912L614.132 741.528L618.609 738.887Z"
                  fill="white"
                />
                <path
                  d="M576.259 760.002C579.042 758.786 581.718 758.34 584.288 758.665C586.857 758.989 589.189 760.001 591.285 761.7C593.359 763.455 595.055 765.842 596.374 768.861C597.693 771.88 598.283 774.726 598.145 777.402C597.984 780.133 597.15 782.552 595.643 784.658C594.136 786.764 591.99 788.425 589.207 789.641C586.424 790.858 583.748 791.303 581.179 790.979C578.61 790.654 576.288 789.614 574.214 787.859C572.119 786.16 570.411 783.802 569.092 780.783C567.774 777.764 567.194 774.889 567.355 772.157C567.493 769.482 568.316 767.092 569.824 764.985C571.331 762.879 573.476 761.218 576.259 760.002ZM577.929 763.825C575.224 765.007 573.499 766.881 572.753 769.448C571.985 772.071 572.381 775.167 573.939 778.735C575.481 782.263 577.466 784.617 579.895 785.797C582.285 786.993 584.833 787.001 587.537 785.819C590.203 784.654 591.928 782.78 592.713 780.195C593.498 777.611 593.12 774.555 591.578 771.026C590.02 767.459 588.017 765.066 585.571 763.847C583.142 762.667 580.594 762.66 577.929 763.825Z"
                  fill="white"
                />
                <path
                  d="M557.337 768.368L567.01 798.019L562.191 799.592L560.042 793.003L560.713 792.784C560.628 795.421 559.823 797.642 558.296 799.445C556.742 801.302 554.705 802.642 552.183 803.465C549.825 804.235 547.733 804.445 545.909 804.095C544.058 803.799 542.461 802.902 541.118 801.405C539.774 799.908 538.672 797.838 537.809 795.194L531.779 776.708L536.72 775.095L542.651 793.277C543.248 795.107 543.982 796.533 544.855 797.553C545.687 798.587 546.705 799.222 547.907 799.46C549.069 799.711 550.443 799.578 552.029 799.06C553.818 798.476 555.258 797.579 556.347 796.368C557.437 795.157 558.123 793.741 558.404 792.119C558.684 790.498 558.52 788.751 557.909 786.88L552.397 769.98L557.337 768.368Z"
                  fill="white"
                />
                <path
                  d="M510.432 781.077C513.646 780.358 516.562 780.363 519.18 781.093C521.765 781.874 523.966 783.289 525.783 785.338C527.558 787.396 528.81 790.053 529.538 793.31C530.248 796.483 530.238 799.379 529.508 801.997C528.788 804.657 527.492 806.898 525.62 808.72C523.706 810.551 521.309 811.788 518.429 812.432C515.674 813.049 513.18 812.971 510.947 812.199C508.714 811.427 506.829 810.051 505.29 808.071C503.71 806.101 502.575 803.571 501.884 800.482L501.45 798.54L525.432 793.176L526.174 796.495L505.573 801.103L506.393 800.065C507.14 803.405 508.47 805.826 510.384 807.327C512.265 808.879 514.625 809.337 517.463 808.703C519.592 808.226 521.296 807.319 522.575 805.981C523.821 804.694 524.624 803.089 524.983 801.168C525.352 799.288 525.28 797.2 524.766 794.903L524.682 794.527C524.113 791.981 523.235 789.919 522.05 788.343C520.823 786.776 519.293 785.715 517.459 785.161C515.635 784.648 513.596 784.644 511.342 785.148C509.547 785.549 507.875 786.208 506.327 787.125C504.746 788.092 503.349 789.391 502.135 791.021L499.584 787.844C500.681 786.283 502.211 784.889 504.172 783.661C506.132 782.433 508.219 781.572 510.432 781.077Z"
                  fill="white"
                />
                <path
                  d="M465.994 793.902C468.083 793.722 470.043 794.046 471.875 794.876C473.71 795.748 475.234 796.991 476.446 798.604C477.659 800.218 478.357 802.09 478.541 804.221C478.71 806.182 478.378 808.057 477.544 809.847C476.672 811.683 475.408 813.209 473.753 814.425C472.101 815.684 470.167 816.409 467.951 816.6C465.905 816.777 463.967 816.45 462.135 815.62C460.26 814.794 458.715 813.553 457.499 811.897C456.287 810.284 455.587 808.391 455.399 806.217C455.219 804.128 455.588 802.185 456.507 800.388C457.383 798.595 458.65 797.111 460.309 795.938C461.969 794.764 463.864 794.085 465.994 793.902Z"
                  fill="white"
                />
                <path
                  d="M417.642 789.009L415.66 829.649L432.066 830.449L431.845 835L393.713 833.14L393.935 828.589L410.341 829.389L412.323 788.749L417.642 789.009Z"
                  fill="white"
                />
                <path
                  d="M388.48 786.51L394.792 819.189L389.413 818.289L384.491 789.747L386.01 790.001L371.757 815.337L367.39 814.607L362.341 786.043L363.797 786.287L349.733 811.654L344.607 810.797L361.268 781.96L366.141 782.775L371.579 812.119L368.858 811.664L383.544 785.685L388.48 786.51Z"
                  fill="white"
                />
                <path
                  d="M334.808 774.219C337.722 775.075 340.059 776.452 341.82 778.351C343.581 780.251 344.719 782.525 345.234 785.173C345.696 787.85 345.464 790.77 344.536 793.931C343.608 797.092 342.232 799.653 340.408 801.614C338.531 803.605 336.338 804.923 333.83 805.569C331.322 806.215 328.612 806.11 325.697 805.254C322.783 804.398 320.446 803.021 318.685 801.121C316.925 799.222 315.813 796.934 315.351 794.256C314.835 791.608 315.042 788.703 315.97 785.542C316.897 782.381 318.3 779.806 320.177 777.815C322.001 775.854 324.167 774.55 326.675 773.904C329.183 773.258 331.894 773.363 334.808 774.219ZM333.633 778.221C330.801 777.39 328.275 777.718 326.055 779.207C323.781 780.724 322.096 783.351 321 787.086C319.915 790.781 319.925 793.861 321.029 796.325C322.093 798.778 324.04 800.42 326.872 801.251C329.663 802.071 332.189 801.743 334.451 800.266C336.712 798.79 338.385 796.204 339.469 792.51C340.566 788.774 340.568 785.653 339.476 783.148C338.372 780.683 336.424 779.041 333.633 778.221Z"
                  fill="white"
                />
                <path
                  d="M299.477 762.648L280.142 803.551L266.336 797.023C261.889 794.92 258.512 792.353 256.207 789.323C253.844 786.313 252.593 782.905 252.452 779.1C252.253 775.315 253.197 771.218 255.281 766.808C257.365 762.399 259.942 759.05 263.011 756.763C266.041 754.457 269.477 753.242 273.321 753.119C277.107 753.017 281.224 754.017 285.671 756.12L299.477 762.648ZM292.801 764.46L284.099 760.346C278.724 757.804 274.072 757.284 270.145 758.787C266.178 760.272 262.906 763.741 260.328 769.195C257.75 774.648 257.155 779.36 258.543 783.329C259.914 787.337 263.267 790.603 268.604 793.126L277.305 797.241L292.801 764.46Z"
                  fill="white"
                />
                <path
                  d="M260.095 742.864L244.178 769.684L239.819 767.097L243.585 760.75L244.137 761.078C242.223 762.877 240.167 763.87 237.967 764.057C235.745 764.28 233.31 763.73 230.663 762.407L228.965 761.623L230.926 757.563L233.937 758.903C236.569 760.167 238.981 760.504 241.171 759.914C243.303 759.339 245.079 757.856 246.498 755.464L255.571 740.178L260.095 742.864Z"
                  fill="white"
                />
                <path
                  d="M230.566 723.199C233.263 725.089 235.225 727.248 236.45 729.674C237.616 732.11 238.054 734.69 237.766 737.413C237.442 740.112 236.323 742.828 234.408 745.561C232.543 748.224 230.397 750.168 227.972 751.394C225.521 752.654 222.993 753.207 220.385 753.051C217.743 752.872 215.213 751.935 212.796 750.241C210.484 748.62 208.861 746.725 207.926 744.555C206.991 742.385 206.737 740.065 207.162 737.594C207.552 735.098 208.655 732.554 210.472 729.961L211.613 728.332L231.739 742.437L229.788 745.222L212.499 733.106L213.819 733.012C211.855 735.815 210.964 738.43 211.145 740.855C211.266 743.291 212.518 745.344 214.9 747.013C216.687 748.265 218.506 748.913 220.356 748.956C222.147 749.009 223.873 748.521 225.534 747.491C227.171 746.497 228.665 745.036 230.015 743.109L230.236 742.793C231.733 740.656 232.665 738.618 233.03 736.68C233.36 734.718 233.112 732.872 232.286 731.144C231.435 729.45 230.064 727.941 228.172 726.615C226.666 725.56 225.053 724.768 223.332 724.242C221.552 723.726 219.651 723.569 217.629 723.771L218.256 719.744C220.149 719.503 222.209 719.694 224.438 720.315C226.666 720.937 228.709 721.898 230.566 723.199Z"
                  fill="white"
                />
                <path
                  d="M208.402 705.996C210.035 707.377 211.242 708.931 212.024 710.656C212.746 712.387 213.013 714.126 212.825 715.872C212.637 717.618 211.963 719.177 210.803 720.549C209.312 722.313 207.697 723.329 205.959 723.596C204.188 723.835 202.094 723.297 199.677 721.982C197.227 720.638 194.206 718.447 190.613 715.409L188.408 713.544L190.604 710.947L192.76 712.77C194.752 714.455 196.473 715.799 197.923 716.8C199.373 717.802 200.628 718.471 201.688 718.808C202.748 719.144 203.662 719.189 204.431 718.942C205.2 718.696 205.915 718.181 206.578 717.396C207.711 716.057 208.179 714.576 207.982 712.953C207.753 711.302 206.838 709.8 205.237 708.447C203.931 707.342 202.525 706.685 201.02 706.477C199.483 706.242 197.981 706.428 196.514 707.037C195.015 707.618 193.671 708.611 192.484 710.015L187.761 715.602C186.048 717.628 185.227 719.427 185.298 720.999C185.342 722.605 186.294 724.195 188.156 725.769C189.593 726.985 191.161 727.946 192.859 728.654C194.53 729.395 196.454 729.902 198.632 730.175L197.56 734.226C196.214 734.096 194.822 733.787 193.385 733.301C191.92 732.846 190.503 732.236 189.134 731.471C187.765 730.705 186.541 729.866 185.463 728.955C183.405 727.214 181.997 725.435 181.237 723.616C180.451 721.83 180.311 719.975 180.817 718.05C181.296 716.158 182.434 714.151 184.229 712.027L196.906 697.031L200.63 700.18L195.949 705.718L195.508 705.345C196.818 704.325 198.222 703.691 199.719 703.444C201.184 703.17 202.662 703.243 204.154 703.665C205.647 704.086 207.062 704.864 208.402 705.996Z"
                  fill="white"
                />
                <path
                  d="M190.699 691.673L167.903 712.955L164.444 709.249L169.51 704.519L169.991 705.035C168.484 705.74 166.988 706.112 165.504 706.152C163.959 706.19 162.516 705.898 161.176 705.277C159.775 704.653 158.505 703.732 157.367 702.512C155.557 700.573 154.567 698.571 154.396 696.507C154.164 694.44 154.828 692.299 156.387 690.082L157.044 690.786C154.768 691.858 152.458 692.142 150.114 691.637C147.71 691.13 145.604 689.908 143.794 687.968C142.189 686.248 141.203 684.534 140.838 682.827C140.444 681.088 140.657 679.309 141.476 677.491C142.264 675.702 143.674 673.858 145.707 671.961L159.92 658.692L163.466 662.492L149.441 675.586C147.346 677.542 146.149 679.332 145.851 680.956C145.522 682.61 146.16 684.296 147.765 686.017C148.904 687.236 150.208 688.038 151.678 688.422C153.118 688.774 154.619 688.69 156.181 688.168C157.682 687.645 159.167 686.697 160.637 685.325L173.536 673.283L177.083 677.083L163.058 690.176C160.963 692.132 159.781 693.938 159.512 695.593C159.182 697.247 159.806 698.918 161.382 700.607C162.549 701.858 163.854 702.66 165.294 703.012C166.735 703.364 168.236 703.28 169.797 702.758C171.298 702.235 172.784 701.287 174.254 699.915L187.153 687.873L190.699 691.673Z"
                  fill="white"
                />
                <path
                  d="M146.36 640.052L108.257 664.437L105.421 660.004L122.284 649.213L107.655 626.347L90.7919 637.138L87.956 632.706L126.059 608.321L128.895 612.753L111.492 623.891L126.121 646.757L143.524 635.619L146.36 640.052Z"
                  fill="white"
                />
                <path
                  d="M117.555 589.506C118.893 592.232 119.457 594.887 119.247 597.468C119.037 600.05 118.13 602.425 116.526 604.595C114.865 606.745 112.556 608.545 109.599 609.997C106.643 611.448 103.825 612.164 101.147 612.145C98.4117 612.105 95.9587 611.379 93.7882 609.967C91.6176 608.554 89.8632 606.484 88.525 603.757C87.1867 601.03 86.6227 598.376 86.8329 595.794C87.0432 593.213 87.9788 590.847 89.6398 588.697C91.2436 586.528 93.5239 584.717 96.4806 583.266C99.4374 581.814 102.283 581.108 105.019 581.147C107.697 581.167 110.121 581.883 112.292 583.296C114.462 584.709 116.217 586.779 117.555 589.506ZM113.811 591.344C112.511 588.693 110.562 587.053 107.965 586.421C105.31 585.77 102.236 586.303 98.7414 588.018C95.2855 589.715 93.0223 591.803 91.9519 594.282C90.8627 596.723 90.9684 599.269 92.2689 601.919C93.5507 604.531 95.4995 606.171 98.1155 606.841C100.731 607.511 103.767 606.998 107.223 605.301C110.718 603.586 113.019 601.479 114.128 598.98C115.199 596.501 115.093 593.955 113.811 591.344Z"
                  fill="white"
                />
                <path
                  d="M107.829 570.689L78.376 580.936L76.7105 576.147L83.2556 573.87L83.4875 574.537C81.824 574.572 80.3054 574.308 78.9317 573.744C77.5035 573.153 76.302 572.303 75.327 571.193C74.2976 570.056 73.5088 568.7 72.9607 567.124C72.0893 564.618 71.9926 562.387 72.6706 560.43C73.2941 558.446 74.7669 556.756 77.0891 555.359L77.4053 556.268C74.8899 556.328 72.6625 555.653 70.7231 554.244C68.7293 552.809 67.2967 550.839 66.4253 548.333C65.6523 546.111 65.4441 544.145 65.8006 542.435C66.1431 540.685 67.0565 539.145 68.5407 537.813C69.9846 536.495 72.0196 535.379 74.6457 534.466L93.008 528.077L94.7157 532.987L76.5957 539.291C73.8888 540.233 72.0708 541.386 71.1416 542.751C70.172 544.131 70.0737 545.932 70.8467 548.154C71.3948 549.73 72.2635 550.991 73.4528 551.936C74.628 552.841 76.0351 553.37 77.674 553.525C79.2584 553.653 81.0001 553.387 82.899 552.726L99.5645 546.928L101.272 551.838L83.1521 558.142C80.4452 559.084 78.6342 560.257 77.7191 561.663C76.7495 563.042 76.6442 564.823 77.4031 567.005C77.9653 568.621 78.834 569.882 80.0092 570.787C81.1844 571.692 82.5915 572.221 84.2304 572.376C85.8149 572.504 87.5565 572.238 89.4554 571.577L106.121 565.779L107.829 570.689Z"
                  fill="white"
                />
                <path
                  d="M89.5838 507.583C90.2852 510.802 90.264 513.718 89.5202 516.333C88.7255 518.914 87.2991 521.108 85.2409 522.914C83.1737 524.678 80.51 525.916 77.25 526.626C74.0736 527.319 71.1785 527.293 68.5648 526.549C65.9092 525.814 63.6759 524.505 61.8648 522.623C60.0447 520.699 58.8203 518.295 58.1919 515.41C57.5907 512.651 57.6821 510.157 58.4661 507.928C59.25 505.699 60.6357 503.821 62.6232 502.293C64.6015 500.723 67.137 499.602 70.2298 498.927L72.1733 498.504L77.406 522.52L74.0833 523.244L69.5884 502.614L70.622 503.44C67.2784 504.169 64.8511 505.486 63.34 507.392C61.778 509.265 61.3067 511.622 61.9261 514.465C62.3906 516.597 63.2884 518.306 64.6193 519.592C65.8994 520.846 67.4991 521.658 69.4184 522.028C71.296 522.407 73.3841 522.346 75.6828 521.845L76.059 521.763C78.6085 521.207 80.6744 520.341 82.2567 519.164C83.8299 517.945 84.8987 516.42 85.4629 514.59C85.9854 512.768 86.0007 510.729 85.5088 508.471C85.1172 506.674 84.4675 504.998 83.5599 503.444C82.6013 501.858 81.3102 500.453 79.6867 499.231L82.8776 496.696C84.432 497.803 85.8176 499.34 87.0345 501.308C88.2513 503.276 89.1011 505.367 89.5838 507.583Z"
                  fill="white"
                />
                <path
                  d="M84.9169 479.5C85.1246 481.113 85.1571 482.705 85.0145 484.276C84.9088 485.799 84.6649 487.254 84.2828 488.641C83.8529 489.99 83.2949 491.184 82.6091 492.221L78.7686 491.034C79.4435 489.912 79.9802 488.721 80.3787 487.462C80.7348 486.208 80.9608 484.949 81.0569 483.686C81.1529 482.422 81.1189 481.154 80.955 479.881C80.6434 477.463 79.963 475.695 78.9135 474.579C77.8641 473.464 76.5757 473.004 75.0484 473.201C73.8605 473.354 72.9791 473.877 72.4041 474.771C71.7868 475.67 71.3992 477.014 71.2415 478.803L70.7148 484.76C70.5076 487.504 69.8099 489.621 68.6217 491.112C67.4281 492.56 65.7494 493.423 63.5858 493.702C61.6766 493.948 59.9804 493.67 58.4971 492.869C56.9659 492.031 55.701 490.749 54.7023 489.023C53.7036 487.296 53.0485 485.224 52.737 482.805C52.5511 481.362 52.522 479.964 52.6495 478.61C52.7771 477.257 53.064 475.969 53.5104 474.747C53.9089 473.487 54.4777 472.379 55.2169 471.421L59.0656 472.672C58.4058 473.577 57.887 474.571 57.509 475.655C57.0887 476.744 56.8223 477.857 56.7098 478.993C56.5495 480.092 56.5404 481.194 56.6825 482.297C56.9886 484.673 57.6875 486.416 58.7794 487.527C59.8712 488.637 61.1808 489.094 62.7081 488.897C63.896 488.744 64.8226 488.236 65.4879 487.374C66.1532 486.512 66.5516 485.253 66.6834 483.597L67.1464 477.649C67.3851 474.814 68.0746 472.633 69.2149 471.105C70.3072 469.541 71.9565 468.617 74.1626 468.333C76.0293 468.092 77.7283 468.391 79.2595 469.229C80.7482 470.072 81.9947 471.379 82.9988 473.147C83.9551 474.879 84.5944 476.997 84.9169 479.5Z"
                  fill="white"
                />
                <path
                  d="M81.6621 443.623L54.5244 444.051L54.6216 450.212L50.5798 450.275L50.4603 442.704L51.8298 444.094L49.3919 444.132C45.2432 444.198 42.0834 443.221 39.9125 441.201C37.7409 439.139 36.4926 435.949 36.1678 431.633L36 429.132L39.9705 428.62L40.2096 431.569C40.3656 433.321 40.7732 434.748 41.4323 435.85C42.0914 436.952 43.0238 437.75 44.2295 438.245C45.4351 438.739 46.9148 438.973 48.6683 438.945L51.3629 438.902L50.4127 439.688L50.2587 429.934L54.3005 429.87L54.4423 438.854L81.5801 438.425L81.6621 443.623Z"
                  fill="white"
                />
                <path
                  d="M82.8586 412.368C82.679 415.4 81.8616 417.987 80.4064 420.13C78.9512 422.272 76.9912 423.892 74.5266 424.989C72.0219 426.041 69.1255 426.469 65.8376 426.274C62.5496 426.08 59.7454 425.314 57.425 423.976C55.0644 422.593 53.288 420.752 52.0958 418.453C50.9035 416.154 50.3972 413.488 50.5768 410.455C50.7564 407.423 51.5738 404.836 53.029 402.693C54.4842 400.551 56.4642 398.953 58.969 397.902C61.4336 396.805 64.3099 396.354 67.5978 396.548C70.8858 396.743 73.7101 397.532 76.0707 398.915C78.3911 400.252 80.1474 402.071 81.3396 404.37C82.5318 406.669 83.0382 409.335 82.8586 412.368ZM78.6953 412.121C78.8698 409.174 77.9828 406.786 76.0343 404.956C74.0457 403.081 71.1085 402.028 67.2227 401.798C63.3796 401.57 60.3814 402.271 58.2281 403.901C56.0773 405.488 54.9146 407.755 54.7401 410.702C54.5681 413.606 55.4551 415.995 57.4011 417.867C59.347 419.74 62.2415 420.79 66.0846 421.017C69.9704 421.248 73.0113 420.549 75.2073 418.922C77.3606 417.292 78.5233 415.025 78.6953 412.121Z"
                  fill="white"
                />
                <path
                  d="M84.1385 391.76L53.248 387.495L53.9412 382.472L61.2507 383.482L61.1629 384.117C58.7623 383.052 57.0493 381.541 56.0237 379.586C54.9559 377.624 54.5178 375.167 54.7096 372.213L54.7734 370.343L59.2753 370.576L59.2091 373.871C59.0656 376.788 59.6907 379.142 61.0844 380.932C62.4416 382.674 64.4974 383.736 67.2517 384.116L84.858 386.547L84.1385 391.76Z"
                  fill="white"
                />
                <path
                  d="M94.1271 343.108C93.2456 346.68 91.6293 349.123 89.2782 350.438C86.9271 351.753 83.9866 351.974 80.4565 351.103L64.5714 347.182L63.1108 353.101L59.1863 352.133L60.6468 346.213L51.3649 343.922L52.6103 338.875L61.8922 341.166L64.2598 331.571L68.1844 332.54L65.8167 342.135L81.2035 345.933C83.5707 346.517 85.5002 346.465 86.9921 345.775C88.4425 345.076 89.4444 343.604 89.9979 341.361C90.1619 340.697 90.2428 340.012 90.2407 339.306C90.2386 338.6 90.2211 337.957 90.1882 337.376L94.2657 337.457C94.4232 338.069 94.4984 338.925 94.4913 340.025C94.4842 341.125 94.3628 342.152 94.1271 343.108Z"
                  fill="white"
                />
                <path
                  d="M95.884 334.245L52.965 319.953L54.607 315.02L74.514 321.65L74.2505 322.441C72.1804 320.805 70.8744 318.837 70.3326 316.537C69.7637 314.183 69.8981 311.748 70.736 309.231C71.5198 306.877 72.5946 305.07 73.9604 303.811C75.3397 302.512 77.0304 301.767 79.0323 301.577C80.9936 301.373 83.2933 301.711 85.9314 302.589L104.377 308.732L102.735 313.664L84.5938 307.623C82.7675 307.015 81.1848 306.758 79.8456 306.853C78.52 306.908 77.4042 307.348 76.4981 308.174C75.6056 308.959 74.8958 310.143 74.3688 311.726C73.7742 313.512 73.6395 315.203 73.9646 316.8C74.2898 318.396 75.0207 319.789 76.1574 320.979C77.294 322.17 78.7958 323.076 80.6627 323.697L97.5259 329.313L95.884 334.245Z"
                  fill="white"
                />
                <path
                  d="M113.453 289.238C112.122 292.251 110.386 294.594 108.244 296.267C106.081 297.884 103.635 298.815 100.908 299.06C98.1985 299.266 95.3176 298.695 92.2655 297.347C89.2917 296.033 86.9685 294.305 85.2958 292.163C83.584 290.004 82.5514 287.63 82.1981 285.042C81.862 282.414 82.2903 279.75 83.4829 277.05C84.6236 274.467 86.1676 272.506 88.1147 271.168C90.0617 269.83 92.2883 269.13 94.7942 269.068C97.3174 268.966 100.027 269.555 102.922 270.834L104.742 271.638L94.8121 294.122L91.7013 292.748L100.231 273.434L100.579 274.71C97.4489 273.327 94.7118 272.96 92.3679 273.608C90.0022 274.2 88.2317 275.827 87.0563 278.488C86.1748 280.484 85.8925 282.394 86.2094 284.218C86.5044 285.985 87.3181 287.584 88.6504 289.015C89.9435 290.428 91.6662 291.61 93.8182 292.56L94.1704 292.716C96.5573 293.77 98.7366 294.289 100.708 294.271C102.697 294.215 104.46 293.613 105.994 292.467C107.49 291.304 108.705 289.666 109.638 287.552C110.381 285.869 110.844 284.133 111.027 282.343C111.188 280.496 110.973 278.6 110.382 276.656L114.454 276.49C115.057 278.3 115.27 280.359 115.093 282.666C114.916 284.973 114.369 287.163 113.453 289.238Z"
                  fill="white"
                />
                <path
                  d="M138.034 264.761L101.738 242.202L104.414 237.895L110.681 241.791L110.343 242.336C108.633 240.467 107.709 238.306 107.571 235.853C107.42 233.341 108.089 230.886 109.58 228.488C111.047 226.126 112.886 224.423 115.096 223.378C117.293 222.275 119.72 221.895 122.378 222.237C125.022 222.521 127.761 223.543 130.595 225.305C133.392 227.044 135.522 229.047 136.983 231.315C138.431 233.525 139.176 235.852 139.218 238.296C139.261 240.74 138.537 243.162 137.047 245.56C135.556 247.958 133.681 249.639 131.421 250.602C129.124 251.542 126.767 251.688 124.348 251.041L124.755 250.387L140.777 260.346L138.034 264.761ZM132.928 244.285C134.486 241.778 134.845 239.255 134.004 236.718C133.15 234.121 131.088 231.807 127.818 229.774C124.511 227.719 121.523 226.895 118.854 227.301C116.207 227.67 114.104 229.109 112.546 231.616C111.011 234.087 110.652 236.61 111.47 239.183C112.289 241.757 114.351 244.072 117.657 246.127C120.927 248.159 123.915 248.984 126.621 248.6C129.29 248.194 131.393 246.756 132.928 244.285Z"
                  fill="white"
                />
                <path
                  d="M147.394 229.122L122.316 210.585L125.329 206.508L131.263 210.894L130.882 211.411C129.284 209.325 128.503 207.179 128.539 204.971C128.54 202.737 129.332 200.37 130.915 197.869L131.866 196.258L135.707 198.618L134.071 201.479C132.549 203.971 131.971 206.337 132.337 208.576C132.695 210.756 133.992 212.672 136.228 214.325L150.521 224.89L147.394 229.122Z"
                  fill="white"
                />
                <path
                  d="M130.016 187.711L125.501 184.008L129.57 179.045L134.085 182.748L130.016 187.711ZM160.54 211.915L136.427 192.138L139.722 188.119L163.836 207.896L160.54 211.915Z"
                  fill="white"
                />
                <path
                  d="M179.064 191.625C176.947 193.92 174.616 195.437 172.073 196.175C169.529 196.913 166.941 196.912 164.308 196.17C161.672 195.367 159.16 193.864 156.77 191.659C154.35 189.425 152.665 187.056 151.717 184.55C150.797 182.014 150.635 179.477 151.229 176.94C151.823 174.403 153.179 171.987 155.296 169.692C156.602 168.277 158.144 167.11 159.923 166.19C161.703 165.271 163.469 164.776 165.222 164.705L166.786 168.594C165.064 168.693 163.492 169.105 162.068 169.828C160.673 170.52 159.526 171.353 158.626 172.328C156.422 174.718 155.48 177.254 155.799 179.935C156.088 182.588 157.631 185.206 160.429 187.788C163.164 190.312 165.928 191.669 168.721 191.859C171.482 192.021 173.965 190.907 176.17 188.517C177.069 187.542 177.807 186.331 178.385 184.885C178.991 183.408 179.259 181.792 179.189 180.039L183.189 181.286C183.26 183.039 182.91 184.87 182.139 186.778C181.366 188.626 180.341 190.241 179.064 191.625Z"
                  fill="white"
                />
                <path
                  d="M199.355 171.869C196.927 174.095 194.396 175.544 191.763 176.216C189.133 176.828 186.519 176.699 183.923 175.829C181.359 174.929 178.949 173.25 176.695 170.789C174.499 168.392 173.064 165.877 172.392 163.243C171.691 160.578 171.698 157.989 172.413 155.477C173.16 152.936 174.621 150.668 176.797 148.673C178.879 146.766 181.08 145.59 183.4 145.146C185.72 144.702 188.041 144.955 190.361 145.904C192.713 146.824 194.958 148.452 197.096 150.786L198.44 152.253L180.322 168.858L178.024 166.351L193.588 152.086L193.395 153.395C191.083 150.872 188.723 149.436 186.316 149.089C183.912 148.681 181.637 149.46 179.493 151.425C177.884 152.9 176.859 154.535 176.418 156.333C175.978 158.07 176.082 159.861 176.728 161.706C177.345 163.52 178.449 165.294 180.038 167.029L180.298 167.313C182.061 169.237 183.849 170.587 185.662 171.362C187.507 172.109 189.362 172.266 191.228 171.833C193.065 171.368 194.835 170.355 196.538 168.794C197.894 167.551 199.015 166.147 199.9 164.58C200.789 162.954 201.353 161.131 201.592 159.113L205.388 160.596C205.214 162.496 204.583 164.467 203.495 166.509C202.407 168.551 201.027 170.337 199.355 171.869Z"
                  fill="white"
                />
                <path
                  d="M236.103 142.944C233.606 144.673 231.065 145.626 228.481 145.801C225.898 145.977 223.414 145.433 221.031 144.169C218.658 142.846 216.534 140.83 214.66 138.121C212.785 135.413 211.658 132.732 211.279 130.081C210.912 127.369 211.265 124.835 212.339 122.479C213.413 120.122 215.199 118.079 217.696 116.35C220.193 114.621 222.733 113.669 225.317 113.493C227.901 113.317 230.379 113.891 232.752 115.214C235.135 116.478 237.264 118.464 239.139 121.173C241.014 123.882 242.135 126.592 242.503 129.303C242.882 131.955 242.534 134.459 241.46 136.816C240.386 139.173 238.6 141.215 236.103 142.944ZM233.729 139.515C236.156 137.834 237.488 135.663 237.726 133C237.975 130.278 236.992 127.317 234.776 124.115C232.585 120.949 230.184 119.021 227.573 118.331C224.998 117.617 222.497 118.1 220.07 119.78C217.678 121.436 216.346 123.607 216.072 126.294C215.799 128.981 216.758 131.907 218.949 135.073C221.165 138.275 223.59 140.238 226.225 140.963C228.836 141.653 231.337 141.17 233.729 139.515Z"
                  fill="white"
                />
                <path
                  d="M254.218 130.629L240.497 107.207L235.182 110.321L233.139 106.833L239.671 103.005L239.135 104.881L237.902 102.777C235.804 99.1966 235.101 95.9643 235.791 93.0805C236.518 90.1749 238.681 87.5192 242.278 85.1133L244.373 83.7373L246.771 86.9432L244.322 88.6017C242.874 89.5992 241.832 90.656 241.197 91.772C240.562 92.8881 240.325 94.0927 240.488 95.3858C240.65 96.679 241.175 98.0823 242.062 99.5958L243.424 101.921L242.273 101.48L250.688 96.5489L252.732 100.037L244.981 104.579L258.703 128.001L254.218 130.629Z"
                  fill="white"
                />
                <path
                  d="M296.632 109.62C293.845 110.828 291.168 111.267 288.6 110.935C286.031 110.603 283.702 109.585 281.61 107.88C279.542 106.119 277.852 103.727 276.542 100.705C275.231 97.6823 274.649 94.8337 274.795 92.1589C274.963 89.428 275.804 87.0115 277.317 84.9097C278.831 82.8078 280.98 81.1527 283.767 79.9444C286.553 78.736 289.231 78.2978 291.799 78.6297C294.367 78.9615 296.686 80.0079 298.755 81.7688C300.846 83.4734 302.546 85.837 303.857 88.8595C305.167 91.882 305.738 94.7588 305.57 97.4898C305.424 100.164 304.594 102.553 303.081 104.655C301.568 106.756 299.418 108.412 296.632 109.62ZM294.973 105.793C297.68 104.618 299.411 102.749 300.164 100.184C300.939 97.5627 300.553 94.466 299.004 90.894C297.473 87.3612 295.494 85.0016 293.068 83.8152C290.681 82.6118 288.134 82.5973 285.426 83.7716C282.757 84.9289 281.027 86.7985 280.235 89.3804C279.442 91.9624 279.812 95.0198 281.343 98.5526C282.892 102.125 284.888 104.523 287.331 105.749C289.757 106.935 292.304 106.95 294.973 105.793Z"
                  fill="white"
                />
                <path
                  d="M315.573 101.308L305.983 71.6291L310.807 70.0701L312.938 76.6654L312.266 76.8825C312.358 74.2449 313.17 72.0267 314.701 70.2277C316.26 68.3749 318.302 67.0407 320.825 66.225C323.186 65.4619 325.278 65.2579 327.101 65.6129C328.951 65.9141 330.546 66.815 331.885 68.3156C333.224 69.8162 334.321 71.8897 335.176 74.5359L341.155 93.0392L336.209 94.6377L330.329 76.4397C329.737 74.6077 329.007 73.1803 328.137 72.1575C327.307 71.1216 326.292 70.4831 325.09 70.2421C323.929 69.9878 322.555 70.1173 320.967 70.6304C319.177 71.2092 317.734 72.1025 316.641 73.3101C315.548 74.5178 314.859 75.9321 314.573 77.5531C314.288 79.1741 314.448 80.921 315.053 82.7937L320.518 99.7093L315.573 101.308Z"
                  fill="white"
                />
                <path
                  d="M362.524 88.7371C359.308 89.4469 356.392 89.4333 353.776 88.6962C351.193 87.908 348.996 86.487 347.185 84.4332C345.416 82.3701 344.171 79.7092 343.452 76.4504C342.752 73.2751 342.77 70.3794 343.507 67.7632C344.235 65.1052 345.537 62.868 347.414 61.0517C349.333 59.2262 351.734 57.9953 354.616 57.3592C357.373 56.7508 359.866 56.8357 362.097 57.6141C364.327 58.3924 366.209 59.7735 367.742 61.7573C369.317 63.7319 370.445 66.2651 371.127 69.3567L371.556 71.2995L347.558 76.5957L346.825 73.2742L367.439 68.7248L366.617 69.7607C365.879 66.4183 364.556 63.994 362.646 62.4875C360.77 60.9301 358.411 60.4649 355.571 61.0917C353.44 61.5619 351.734 62.4643 350.451 63.7989C349.201 65.0824 348.394 66.6846 348.029 68.6053C347.655 70.4841 347.722 72.5725 348.229 74.8704L348.312 75.2464C348.874 77.795 349.746 79.859 350.926 81.4386C352.149 83.009 353.676 84.0739 355.508 84.6336C357.331 85.1514 359.37 85.1614 361.626 84.6636C363.422 84.2672 365.095 83.613 366.646 82.7011C368.23 81.7383 369.631 80.4433 370.849 78.8163L373.391 82.0012C372.289 83.5588 370.756 84.9487 368.792 86.1709C366.827 87.3931 364.738 88.2485 362.524 88.7371Z"
                  fill="white"
                />
                <path
                  d="M406.99 76.0437C404.902 76.218 402.942 75.8877 401.113 75.0529C399.28 74.1755 397.759 72.9285 396.551 71.3119C395.344 69.6954 394.651 67.8212 394.473 65.6894C394.309 63.7281 394.647 61.8538 395.485 60.0666C396.362 58.2331 397.63 56.7105 399.289 55.4987C400.944 54.2444 402.88 53.5247 405.097 53.3397C407.143 53.169 409.081 53.5011 410.91 54.3359C412.782 55.1671 414.324 56.4123 415.536 58.0715C416.744 59.6881 417.438 61.5836 417.62 63.758C417.794 65.8472 417.419 67.789 416.496 69.5834C415.615 71.3742 414.343 72.8542 412.681 74.0233C411.019 75.1924 409.122 75.8659 406.99 76.0437Z"
                  fill="white"
                />
              </svg>
            </div>
            <VideoBlockHeading>
              <SubtitleText isCentered variant={"primary"} color="color-1">
                Win this £2.5 million Wiltshire Dream Home
              </SubtitleText>
            </VideoBlockHeading>
          </TourTabpanel>
        </HeroTabpanel>
        <HeroTabpanel id="gallery">
          <GalleryTabpanel>
            {heroGalleryImages.map((imageName, i) => (
              <GalleryItem key={i}>
                <img
                  srcSet={`
                      ${withUrl(`${imageName}_800x600`)} 800w,
                      ${withUrl(`${imageName}_1600x900`)} 1600w,
                      ${withUrl(`${imageName}_3840x2160`)} 3840w,
                    `}
                  sizes={`(max-width: 800px) 800px, (max-width: 1600px) 1600px, 3840px`}
                  src={`${withUrl(`${imageName}_1600x900`)}`}
                  alt="property"
                />
              </GalleryItem>
            ))}
          </GalleryTabpanel>
        </HeroTabpanel>
        <HeroTabpanel id="floorplan">
          <FloorplanTabpanel>
            <img
              className="hero-block-floorplan__img"
              srcSet={`${process.env.REACT_APP_AWS_S3_URL}floorplan/floorplan_mobile.jpg 1024w, ${process.env.REACT_APP_AWS_S3_URL}floorplan/floorplan_desctop.jpg 1910w`}
              sizes="(min-width: 768px) 1910px, 100vw"
              width={1910}
              height={720}
              alt="floorplan"
            />
          </FloorplanTabpanel>
        </HeroTabpanel>
        <HeroTabpanel id="info">
          <InfoTabpanel>
            {heroInfoData.map(({ title, content }, index) => {
              return (
                <HeroInfoItem
                  title={title}
                  content={content}
                  key={index}
                  renderSubtitle={(string) => HtmlReactParser(string)}
                />
              );
            })}
          </InfoTabpanel>
        </HeroTabpanel>
      </HeroTabpanels>
    </HeroContainer>
  );
}
