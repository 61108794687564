import React, { useEffect, useState } from "react";
import { TitleText } from "raffle-house-uikit";
import PartnersSlider from "../PartnersSlider/PartnersSlider";
import { getAllCharities } from "../../services/charities";
import imageLogo from "../../assets/images/footer/logo-fundraising-regulator.png";
import "./styles.css";

const CharityBlock = (props) => {
  const [charitiesList, setCharitiesList] = useState([]);

  useEffect(() => {
    getAllCharities()
      .then((result) => {
        if (Array.isArray(result)) {
          setCharitiesList(result);
        }
      })
      .catch((error) => console.error(error));
  }, []);
  return (
    <div className="charity-block">
      <TitleText isCentered as="h2" variant={"primary"} color="color-5">
        Dream big. <br className="mobile-spacer" /> Do good.
      </TitleText>
      <div className="charities-list">
        <div className="left-side">
          <p className="title">
            Your donation.
            <br /> <span>Your choice.</span>
          </p>
          <p className="sub">
            Win a home and donate to one of our charity partners.
          </p>
          <div className="charity-logo">
            <img src={imageLogo} alt="fundraising-regulator" />
          </div>
        </div>
        <div className="right-side">
          <PartnersSlider isDark isCharitySlider charityList={charitiesList} />
        </div>
      </div>
    </div>
  );
};

export default CharityBlock;
