import React from 'react';
import Marquee from 'react-fast-marquee';

const PartnersSlider = (props) => {
  return (
    <Marquee speed={70} autoFill>
      {props.charityList && props.charityList.length ? props.charityList.map((charity, i) => {
        return (
          <div key={i} data-index={i} className={props.isCharitySlider ? 'charity-rounded-image' : undefined}>
            <img
              alt={charity.name}
              src={`${process.env.REACT_APP_AWS_S3_URL}${charity.image}`}
              style={{ marginLeft: props.isCharitySlider ? '0px' : '40px' }}
            />
          </div>
        );
      }) : null}
    </Marquee>
  );
};

export default PartnersSlider;
