import axios from 'axios';
import config from '../config';

const getAllCharities = async() => {
  const result = await axios.get(
    `${config.API_BASE_URL}/charities/get/landing-all`,
  );
  return result.data;
}

export {getAllCharities};